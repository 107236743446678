var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":"การเสนอคณะกรรมการประเมิน ตำแหน่งครูผู้ช่วย"}},[_c('v-tabs',{attrs:{"background-color":"grey lighten-2","color":"basil","grow":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',[_c('v-icon',[_vm._v("mdi-alert")]),_vm._v(" แจ้งยกเลิกเสนอคณะกรรมการ ")],1),_c('v-tab',[_c('v-icon',[_vm._v("mdi-check-decagram")]),_vm._v(" การเสนอชื่อคณะกรรมการ ")],1),_c('v-tab-item',[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerscc,"items":_vm.manage_assistant_teacherscc,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.mt_admissions_status",fn:function(ref){
var item = ref.item;
return [(item.mt_admissions_status === 'missing')?_c('v-chip',{attrs:{"dark":"","color":"red"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v("ไม่มารายงานตัว ")],1):_vm._e(),(item.mt_admissions_status === 'receipt')?_c('v-chip',{attrs:{"dark":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v("มารายงานตัวปกติ ")],1):_vm._e()]}},{key:"item.mt_date_app_now",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("moment")(item.mt_date_app_now,"add", "543 year"),"D MMMM YYYY"))+" ")])]}},{key:"item.mt_committe_status",fn:function(ref){
var item = ref.item;
return [(item.mt_committe_status === 'CC')?_c('div',[_c('v-chip',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.updateAssisstant(item.mt_id)}}},[_c('v-icon',[_vm._v("mdi-information-outline")]),_vm._v(" แจ้งรายงานข้อมูลผิดพลาด")],1)],1):_vm._e(),(item.mt_committe_status === 'CF')?_c('div',[_c('v-chip',{attrs:{"color":"success","dark":""}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1)],1):_vm._e()]}},{key:"item.mt_committe_file",fn:function(ref){
var item = ref.item;
return [(item.mt_committe_file)?_c('div',[_c('v-btn',{attrs:{"color":"info","fab":"","small":""},on:{"click":function($event){return _vm.viewpdfmt_committe_file(item.mt_committe_file)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1):_c('div',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert")])],1)]}},{key:"item.mt_committe_datetime",fn:function(ref){
var item = ref.item;
return [(item.mt_committe_datetime)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("moment")(item.mt_committe_datetime,"add", "543 years"),"D MMMM YYYY : HH:mm:ss a"))+" ")]):_c('div',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert")])],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v(" Your search for \""+_vm._s(_vm.search)+"\" found no results. ")])],1)],1)],1),_c('v-tab-item',[_c('v-card',{staticClass:"mb-4 pa-2"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"href":'#/admin/print_assistantcommittee/?mt_appoin_day=' +
                      _vm.mt_appoin_day +
                      '&mt_appoin_month=' +
                      _vm.mt_appoin_month +
                      '&mt_appoin_year=' +
                      _vm.mt_appoin_year,"target":"_blank","rounded":"","color":"info"}},[_c('v-icon',[_vm._v("mdi-printer")]),_vm._v(" พิมพ์รายชื่อกรรมการประเมิน (1.1) รวม")],1),_c('v-btn',{attrs:{"href":'#/admin/print_assistantcommittee_vip/?mt_appoin_day=' +
                      _vm.mt_appoin_day +
                      '&mt_appoin_month=' +
                      _vm.mt_appoin_month +
                      '&mt_appoin_year=' +
                      _vm.mt_appoin_year,"target":"_blank","rounded":"","color":"info"}},[_c('v-icon',[_vm._v("mdi-printer")]),_vm._v(" พิมพ์รายชื่อผู้่ทรงคุณวุฒิ (2.1) รวม")],1)],1)])],1)],1),_c('v-card',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("จำนวนทั้งสิ้น")]),_c('th',{staticClass:"text-center"},[_vm._v("เสนอกรรมการ")]),_c('th',{staticClass:"text-center"},[_vm._v("แนบไฟล์เสนอ")]),_c('th',{staticClass:"text-center"},[_vm._v("ขอยกเลิกรายการ")]),_c('th',{staticClass:"text-center"},[_vm._v("ยังไม่ได้ดำเนินการ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"fab":"","color":"info"},on:{"click":function($event){return _vm.manage_assistant_teacherQueryTimeYear()}}},[_vm._v(_vm._s(_vm.dashboardShow.count_teach_alls)+" ")])],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"fab":"","color":"warning"},on:{"click":function($event){_vm.manage_assistant_teacherShow(
                        (_vm.mt_committe_status = 'CF')
                      )}}},[_vm._v(_vm._s(_vm.dashboardShow.count_teach_cfs)+" ")])],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"fab":"","color":"success"},on:{"click":function($event){_vm.manage_assistant_teacherShow(
                        (_vm.mt_committe_status = 'CK')
                      )}}},[_vm._v(_vm._s(_vm.dashboardShow.mt_committe_files)+" ")])],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"fab":"","color":"red"},on:{"click":function($event){_vm.manage_assistant_teacherShow(
                        (_vm.mt_committe_status = 'CC')
                      )}}},[_vm._v(_vm._s(_vm.dashboardShow.count_teach_ccs)+" ")])],1),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"fab":"","color":"warning"},on:{"click":function($event){_vm.manage_assistant_teacherShow(
                        (_vm.mt_committe_status = 'null')
                      )}}},[_vm._v(_vm._s(_vm.dashboardShow.count_teach_nulls)+" ")])],1)])])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.showHeaders,"items":_vm.manage_assistant_teachers,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',[_c('v-select',{attrs:{"items":_vm.headers,"label":"เลือกคอลัมน์ที่ต้องการแสดง","multiple":"","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var item = ref.item;
                      var index = ref.index;
return [(index < 6)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 6)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedHeaders.length - 6)+" คอลัมน์)")]):_vm._e()]}}]),model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}})],1)]},proxy:true},{key:"item.mt_committe_status",fn:function(ref){
                      var item = ref.item;
return [(item.mt_committe_status === 'CC')?_c('div',[_c('v-chip',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.updateAssisstant(item.mt_id)}}},[_c('v-icon',[_vm._v("mdi-information-outline")]),_vm._v(" แจ้งรายงานข้อมูลผิดพลาด")],1)],1):(item.mt_committe_status === 'CF')?_c('div',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-checkbox-marked-circle-outline")])],1):_c('div',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert")])],1)]}},{key:"item.mt_committe_file",fn:function(ref){
                      var item = ref.item;
return [(item.mt_committe_file)?_c('div',[_c('v-btn',{attrs:{"color":"info","fab":"","small":""},on:{"click":function($event){return _vm.viewpdfmt_committe_file(item.mt_committe_file)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1):_c('div',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert")])],1)]}},{key:"item.mt_committe_datetime",fn:function(ref){
                      var item = ref.item;
return [(item.mt_committe_datetime)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("moment")(item.mt_committe_datetime,"add", "543 years"),"D MMMM YYYY : HH:mm:ss a"))+" ")]):_c('div',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert")])],1)]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [(item.mt_committe_ipaapprove === 'pass')?_c('v-btn',{attrs:{"fab":"","small":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.updatemanage_assistant_teacher(item.mt_id)}}},[_c('v-icon',[_vm._v("mdi-update")])],1):(item.mt_committe_ipaapprove === 'dontpass')?_c('v-btn',{attrs:{"fab":"","small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.updatemanage_assistant_teacher(item.mt_id)}}},[_c('v-icon',[_vm._v("mdi-update")])],1):_c('v-btn',{attrs:{"fab":"","small":"","color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.updatemanage_assistant_teacher(item.mt_id)}}},[_c('v-icon',[_vm._v("mdi-update")])],1)]}},{key:"item.cancels",fn:function(ref){
                      var item = ref.item;
return [_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.evaluate_assistant(item.id_card)}}},[_vm._v(" mdi-cancel ")])]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v(" Your search for \""+_vm._s(_vm.search)+"\" found no results. ")])],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.pdfmt_committe_filedialog),callback:function ($$v) {_vm.pdfmt_committe_filedialog=$$v},expression:"pdfmt_committe_filedialog"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('embed',{attrs:{"src":'/HRcommitteeAssistantTeach/' + _vm.pdf_files,"width":"100%","height":"700px"}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }